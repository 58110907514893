




import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { dispatchCreateCause } from '@/store/crud/actions';
import CauseForm from '@/components/forms/CauseForm.vue';
import { ICreateCause } from '@/interfaces/causes';


@Component({ components: { CauseForm } })
export default class Cause extends AppComponent {
  @Prop({ type: String, required: false }) public causeId: string;

  public causeUpdated() {
    this.$router.push('/main/content/causes');
  }

  public async causeCreated(causePayload: ICreateCause) {
    if (causePayload && Object.keys(causePayload).length > 0) {
      const result = await dispatchCreateCause(this.$store, causePayload);
      if (result) {
          this.$router.push('/main/content/causes');
      }
    }
  }

  public cancel() {
    this.$router.push('/main/content/causes');
  }
}
