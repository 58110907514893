









































import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICause, ICreateCause, IUpdateCause } from '@/interfaces/causes';
import { dispatchGetCauseById, dispatchUpdateCause } from '@/store/crud/actions';

@Component({})
export default class CauseForm extends AppComponent {
  @Prop({ type: String, required: false, default: null }) public causeId: string;

  public valid = false;
  public name: string = '';
  public description: string = '';

  public cause: ICause | null = null;
  public forceCreation: boolean = false;
  public busId: number = 0;

  public async initiView() {
    if (!this.isCreation && this.causeId && this.causeId.length > 0) {
      this.cause = await dispatchGetCauseById(this.$store, this.causeId );
      if (!this.cause || Object.keys(this.cause).length === 0) {
        this.forceCreation = true; // force creation if element is not found or error with the API
      }
    }
    this.reset();
  }

  /**
   * Resets the form.
   * if the id is provided, this will be an edit form so existing data is injected into the form
   * otherwise, this will be a creation form and the data will be empty
   */
  public reset() {
    if (this.isCreation) {
      this.name = '';
      this.description = '';
    } else {
      if (this.cause) {
        this.name = this.cause.name || '';
        this.description = this.cause.description || '';
      } else {
        // weird  case, we just force creation and re-call the function
        this.forceCreation = true;
        this.reset();
        return;
      }
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$emit('cause:cancelled');
  }

  public get isCreation() {
    return this.causeId === null || this.forceCreation;
  }

  public async mounted() {
    this.initiView();
    this.busId = this.$bus.$on('cause:reset', this.initiView);
  }

  public beforeDestroy() {
    this.$bus.$off('cause:reset', this.busId);
  }



  /**
   * Updates the item in the DB
   * Checks if the form variables are valid / available
   * and only sends the data if it changed from the original data
   * @Argument {boolean} silent - false by default, if true it emits an
   * <element>:updated event to notify parent components that a new item has been
   * created
   */
  public async updateCause(silent = false) {
    const causePayload: IUpdateCause = {};
    if (this.cause && this.cause.id) {
      this.setFieldIfExistAndValueChanged(causePayload, 'name', this.name, this.cause.name);
      this.setFieldIfChanges(causePayload, 'description', this.description, this.cause.description);

      if (Object.keys(causePayload).length > 0) {
        const result = await dispatchUpdateCause(
          this.$store, { causeId: this.cause.id, cause: causePayload });
        if (result) {
          if (!silent) {
            this.$emit('cause:updated', result);
          }
        } else {
          if (!silent) {
            this.toast('Error while updating cause', true);
          }
        }
      }
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.isCreation) {
        const protoCause: ICause = {
          name: this.name,
          description: this.description,
        };
        // sanitize creation data
        if (!this.hasValue(protoCause.name)) {
          this.toast('Name is required', true); // name is required
          return;
        }
        if (!this.hasValue(protoCause.description)) { delete protoCause.description; }
        const createCause: ICreateCause = protoCause as ICreateCause;
        this.$emit('cause:created', createCause);
      } else {
        this.updateCause();
      }
    }
  }
}
